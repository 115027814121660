<template>
    <div class="transfer-to-account">
        <div class="transfer-box">
            <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span class="card-title">{{ $t('RewardCenter.TransferToAcc.Title') }}</span>
            </div>
            <div>
                <el-alert center type="warning" :title="$t('RewardCenter.TransferToAcc.Tip')"></el-alert>
            </div>
            <div class="content-box ct-margin-top">
                <div class="ct-box">
                    <div class="ct-lable">{{ $t('RewardCenter.TransferToAcc.TransferAmount') }}</div>
                    <CInput type="number" style="margin-bottom:0" v-model="amount" />
                </div>
                <div class="ct-box ct-margin-top">
                    <button @click="submitTransferToAcc" class="btn custer sbtn" type="button">{{ $t('RewardCenter.TransferToAcc.TransferConfirm') }}</button>
                </div>
            </div>
            </el-card>
        </div>
    </div>
</template>

<script>
import { transferToAcc } from "../../api/http"
export default {
    data() {
        return {
            amount: null
        }
    },
    methods: {
        // 提交转出至余额
        submitTransferToAcc() {
            if(this.amount == null) {
                return
            }
            let data = {
                type: "agent_transfer_to_account",
                uid: localStorage.getItem('uid'),
                amount: this.amount
            }
            transferToAcc(data).then((res) => {
                if(res.code == 200) {
                    this.$message.success(res.message)
                    this.amount = null
                    this.$router.push("/reward-center/account")
                }else {
                    this.$message.error(res.message)
                }
            })
        }
    }
}
</script>

<style scoped>
.sbtn:hover{
  background: rgba(0,190,164, .8) !important;
}
.sbtn:focus{
  border-color: #00BEA4;
  box-shadow: 0 0 0 .25rem rgba(0,190,164, .5) !important;
}
.custer{
  background: #00bea4;
  color: #fff; 
  width: 100%;
  outline:0;
}
.transfer-to-account {
  padding-left: 2.6rem;
  height: 100vh;
  background: #fff;
}
.transfer-box{
    width: 83%;
    margin: 0 auto;
    overflow: hidden;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.card-title {
  font-weight: bold;
  font-size: 1.1rem;
}
.content-box{
    width: 360px;
    margin: 0 auto;
}
.ct-box{
    display: flex;
    align-items: center;
}
.ct-lable{
    width: 100%;
    color: #42474d;
    font-size: 14px;
    font-family: AxLLCircular, Helvetica, Arialsans-serif;
}
.ct-margin-top{
    margin-top: 2rem;
}
</style>